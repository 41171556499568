.country-form {
  &__description {
    .text-field {
      &__box {
        padding-left: 0;
        padding-right: 0;
      }
    }
  }
}

.country-list {
  display: flex;
  flex-direction: column;
  margin: 10px;
  padding: 10px;

  &__pagination {
    display: flex;
    justify-content: flex-end;
  }

  &__table {
    border: 1px solid $comparative-snow;
    border-top: none;
  }

  &__header {
    display: flex;
    font-size: 16px;
    font-weight: bold;
    justify-content: space-between;
    padding-left: 8px;
    padding-right: 10px;
  }

  &__data {
    border-top: 1px solid $comparative-snow;
    display: flex;
    font-size: 14px;
    height: 42px;
    justify-content: space-between;
    padding-left: 10px;
    padding-right: 10px;

    &-value {
      align-items: center;
      display: flex;
      justify-content: flex-start;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      width: 12.33%;

      &-clickable {
        cursor: pointer;

        &-icon {
          font-size: 10px;
          margin-left: 20px;

          svg {
            fill: $black;
          }
        }
      }

      &--actions {
        justify-content: flex-end;
        width: 20%;
      }

      &--header-actions {
        justify-content: center;
        width: 20%;
      }
    }

    &--nested {
      justify-content: flex-start;
      padding-left: 30px;
    }
  }

  &__data:nth-child(odd) {
    background: $astronomical-silver;
  }
}
