.banner-form {
  &__description {
    .text-field {
      &__box {
        padding-left: 0;
        padding-right: 0;
      }
    }
  }
}
