.dashboard {
  background: $historic-milky;
  margin: -13px;
  min-height: calc(100vh - 54px);
  &__header {
    font-size: 33px;
    padding: 20px 0 20px 60px;
    text-align: left;
  }
  &__cards {
    display: flex;
    flex-wrap: wrap;
    margin-left: 45px;
  }
  &-card {
    align-items: center;
    background: $white;
    border-radius: 4px;
    box-shadow: $cool-gray-20;
    color: black;
    display: flex;
    height: 100px;
    justify-content: space-between;
    margin: 10px 20px;
    max-width: 30%;
    text-decoration: none;
    width: 100%;

    &:hover {
      box-shadow: 0 3px 6px rgba($cool-gray-20, 0.16);
      cursor: pointer;
    }

    @media only screen and (max-width: 991px) {
      max-width: 40%;
      margin: 10px;
    }

    @media only screen and (max-width: 767px) {
      max-width: 100%;
      margin: 10px;
    }

    &__left,
    &__right {
      flex: 1 0 25%;
    }

    &__left {
      svg {
        height: 60px;
      }
    }

    &__middle {
      display: flex;
      flex: 2 0 50%;
      flex-direction: column;
      text-align: left;

      &--model-name {
        font-size: 16px;
        font-weight: bold;
      }

      &--recent-entries,
      &--last-modified-at {
        font-size: 12px;
        &--value {
          color: $disturbed-violet;
          font-weight: bold;
        }
      }
    }

    &__right {
      align-self: flex-end;
      font-size: 12px;
      margin-bottom: 28px;

      &--total-entries {
        &--value {
          color: $disturbed-violet;
          font-weight: bold;
        }
      }
    }
  }
}
