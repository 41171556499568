// !!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!
// !! COPIED FROM RCL as it's not available in Meta, yet !!
// !! Please don't modify                                !!
// !!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!

$attracting-peach: #ff605d;
$cool-gray-30: #474747;

.select-box {
  &__label,
  &__error {
    color: $cool-gray-20;

    &--tiny {
      font-size: 12px;
    }

    &--small {
      font-size: 14px;
    }

    &--large {
      font-size: 16px;
    }
  }

  &__width {
    &--tiny {
      .select-box__control {
        width: 150px;
      }

      .select-box__menu {
        width: 150px;
      }
    }

    &--small {
      .select-box__control {
        width: 300px;
      }

      .select-box__menu {
        width: 300px;
      }
    }

    &--large {
      .select-box__control {
        width: 450px;
      }

      .select-box__menu {
        width: 450px;
      }
    }
  }

  &-container {
    .select-box__control {
      min-height: 24px;
    }

    .select-box__option {
      align-items: center;
      display: flex;
    }

    &--notMulti.select-box-container--tiny {
      .select-box__indicators {
        height: 24px;
      }
    }

    &--notMulti.select-box-container--small {
      .select-box__indicators {
        height: 32px;
      }
    }

    &--notMulti.select-box-container--large {
      .select-box__indicators {
        height: 40px;
      }
    }

    .select-box__multi-value__remove:hover {
      background-color: $cool-gray-20;
      color: $white;
    }

    &--tiny {
      .select-box__control,
      .select-box__indicators,
      .select-box__option {
        font-size: 12px;
        min-height: 24px;
      }

      .select-box__value-container {
        padding: 0 8px;
      }
    }

    &--small {
      .select-box__control,
      .select-box__option {
        font-size: 14px;
        min-height: 32px;
      }
    }

    &--large {
      .select-box__control,
      .select-box__option {
        font-size: 16px;
        min-height: 40px;
      }
    }

    &--error {
      .select-box__control {
        border-color: $attracting-peach;
      }
    }
  }

  &__error {
    font-size: 14px;
  }

  &__pre-icon + &__placeholder-text {
    &--tiny {
      margin-left: 17px;
    }

    &--small {
      margin-left: 20px;
    }

    &--large {
      margin-left: 25px;
    }
  }

  &__pre-icon {
    height: 18px;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    width: 18px;

    svg {
      fill: $cool-gray-30;
      height: 100%;
      width: 100%;
    }

    &--small {
      height: 16px;
      width: 16px;
    }

    &--tiny {
      height: 14px;
      width: 14px;
    }

    &--large {
      height: 20px;
      width: 20px;
    }
  }
}
