.airline-list {
  display: flex;
  flex-direction: column;
  margin: 10px;
  padding: 10px;
  text-align: left;

  &__pagination {
    display: flex;
    justify-content: flex-end;
  }

  &__header {
    display: flex;
    font-size: 16px;
    font-weight: bold;
    justify-content: space-between;
    padding-left: 8px;
    padding-right: 10px;

    &--no-padding {
      padding: 0;
    }
  }

  &__search {
    &-post-icon {
      right: 12px;
      top: -13px;
      width: 20px;
    }
  }

  &__title {
    color: $bogus-licorice;
    font-size: 36px;
    font-weight: bold;
    padding-bottom: 20px;
    text-align: left;
  }

  &__data {
    border-top: 1px solid $comparative-snow;
    display: flex;
    font-size: 14px;
    height: 42px;
    justify-content: space-between;
    padding-left: 10px;
    padding-right: 10px;

    &-value {
      align-items: center;
      display: flex;
      justify-content: flex-start;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      width: 300px;

      &-clickable {
        cursor: pointer;
        padding-left: 30px;

        &-icon {
          font-size: 10px;
          margin-left: 10px;

          svg {
            fill: $black;
          }
        }
      }

      &--actions {
        justify-content: center;
        width: 350px;
      }

      &--nested-actions {
        justify-content: flex-end;
        width: 350px;
      }

      &--nested-actions-wide {
        justify-content: flex-end;
        width: 450px;
      }
    }

    &--nested {
      align-items: center;
      padding-left: 25px;
    }
  }

  &__data:nth-child(odd) {
    background: $astronomical-silver;
  }

  &__value {
    align-items: center;
    display: flex;
    justify-content: flex-start;
    max-height: 42px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    width: 300px;

    &-clickable {
      cursor: pointer;

      &-icon {
        font-size: 12px;
        margin-left: 4px;

        &:last-of-type {
          margin-left: 0;
        }

        svg {
          fill: $leaky-rust;
        }

        &--active {
          svg {
            fill: $black;
          }
        }
      }
    }

    &-title {
      text-transform: capitalize;
    }

    &--actions {
      justify-content: center;
      width: 350px;
    }
  }

  &__table {
    border: 1px solid $comparative-snow;
    border-top: none;
  }

  &__modal {
    &-wrapper {
      padding: 0 12px 22px;
    }

    &-fare-brand {
      padding-top: 10px;
    }
  }
}

.airline-form {
  &__description {
    .text-field {
      &__box {
        padding-left: 0;
        padding-right: 0;
      }
    }
  }

  &__cabin-class {
    .select-box {
      &__width {
        &--small {
          .select-box {
            &__control {
              width: 100%;
            }
          }
        }
      }
    }
  }
}
