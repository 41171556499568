// !!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!
// !! COPIED FROM RCL as it's not available in Meta, yet !!
// !! Please don't modify                                !!
// !!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!

$earthly-royal: #3ab974;
$prime-marble: #d0d0d0;
$rightful-chocolate: #e04e3f;

.badge {
  align-items: center;
  border-radius: 50%;
  box-sizing: border-box;
  display: flex;
  justify-content: center;

  &--active {
    background: $earthly-royal;
  }

  &--inactive {
    background: $prime-marble;
  }

  &--new {
    background: $rightful-chocolate;
  }

  &--small {
    height: 12px;
    width: 12px;
  }

  &--normal {
    height: 24px;
    width: 24px;
  }

  &--number {
    color: $white;
    font-size: 12px;
  }
}
