.glossary-form {
  &__keywords {
    .select-box {
      &__width {
        &--small {
          .select-box {
            &__control {
              width: 100%;
            }
          }
        }
      }

      &__label {
        &--small {
          display: flex;
          font-size: 1.6rem;
          margin-bottom: 0.3rem;
        }
      }
    }
  }
}
