.search-for-best-match {
  &__wrapper {
    display: inline-block;
    margin-left: auto;
  }

  &-content {
    color: $merciless-bronze;

    &__hotel {
      border: solid $cool-gray-88 1px;
      margin-bottom: 20px;
      padding: 10px;

      Button {
        margin-top: 10px;
      }
    }

    &__submit {
      margin: 10px;
      float: left;
    }
    &__cancel {
      margin: 10px;
      float: right;
    }
  }
}
