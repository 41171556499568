.common {
  &-form {
    margin: 10px;
    padding: 10px;

    &__previous-image {
      display: block;
      margin: 0 auto;
      padding-bottom: 10px;
      width: 480px;

      img {
        display: block;
        height: auto;
        max-width: 100%;
      }
    }

    &__map {
      height: 400px;
      margin-bottom: 20px;
      width: 100%;
    }

    &__container {
      padding-top: 10px;
      display: flex;
      text-align: left;
    }

    &__error-message {
      border-top: 1px solid $silver-chalice;
      padding-top: 10px;
    }

    &__title {
      color: $bogus-licorice;
      font-size: 36px;
      font-weight: bold;
      padding-bottom: 20px;
      text-align: left;
    }

    &__header {
      color: $bogus-licorice;
      font-size: 16px;
      font-weight: bold;
      margin: 0 auto 5px auto;
      text-align: left;
      width: 65%;
    }

    &__field {
      padding-bottom: 20px;

      &-multi-select-box {
        text-align: left;

        .select-box__label {
          display: block;
          margin-bottom: 5px;
        }
      }

      .label-hint {
        cursor: pointer;
        display: inline-block;
        height: 1.6rem;
        margin-left: 0.6rem;
        position: relative;
        width: 1.6rem;
      }

      .text-field__box {
        max-width: 100%;
        min-height: 60px;
        min-width: 100%;
        padding-left: 0;
        padding-right: 0;
      }
    }

    &__submit-button {
      align-items: center;
      display: flex;
      justify-content: flex-start;
      padding-top: 20px;
      width: 40%;

      &-loading {
        align-items: center;
        display: flex;
      }

      &--with-margin {
        margin: 20px;
      }

      &--with-left-padding {
        padding-left: 20;
      }
    }
  }

  &-show {
    border-top: 1px solid darkgrey;
    display: flex;
    padding-top: 10px;

    &__map {
      height: 400px;
      margin-bottom: 20px;
    }

    &__infos {
      display: flex;
      flex-direction: column;
      padding-right: 5px;
      width: 50%;

      &--field {
        background: $shining-steel;
        border-radius: 4px;
        color: white;
        font-weight: bold;
        font-size: 12px;
        margin-bottom: 8px;
        padding: 5px;
        width: fit-content;
      }

      &--value {
        background: $smooth-satin;
        border: 1px solid $comparative-snow;
        border-radius: 4px;
        font-size: 16px;
        margin-top: 0;
        margin-bottom: 20px;
        overflow-wrap: break-word;
        overflow-x: auto;
        padding: 5px 10px;
      }
    }

    &__images {
      display: flex;
      flex-direction: column;
      padding-left: 5px;
      width: 50%;
    }
  }
}

.sanitized-html {
  img {
    width: 100%;
  }
}

.lds-dual-ring {
  display: block;
  height: 40px;
  margin: 100px auto;
  padding: 20px;
  width: 40px;
}

.lds-dual-ring:after {
  animation: lds-dual-ring 1.2s linear infinite;
  border: 4px solid $disturbed-violet;
  border-color: $disturbed-violet transparent $disturbed-violet transparent;
  border-radius: 50%;
  content: ' ';
  display: block;
  height: 30px;
  margin: 1px;
  width: 30px;
}

.dashboard-card {
  &__middle {
    &--model-name {
      margin-bottom: 10px;
    }
  }
}

.resizable-input {
  color: $merciless-bronze;
}

.react-selectize-search-field-and-selected-values {
  font-size: 1.6rem;
}

@keyframes lds-dual-ring {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
