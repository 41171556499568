html {
  font-size: 10px;
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
}

.App-header {
  display: flex;
  flex-direction: column;
  font-size: calc(10px + 2vmin);

  &__title {
    font-weight: bold;
    padding-left: 10px;
    text-align: left;
  }
}

.App-body {
  .side-nav {
    background: $historic-milky;
    border: 1px solid $forward-satin;
    border-top: 0;
    bottom: 0;
    overflow-x: hidden;
    overflow-y: auto;
    padding: 0;
    position: fixed;
    top: 50px;

    &__content {
      align-items: flex-start;
      display: flex;
      flex-direction: column;

      &--header {
        color: $awake-amber;
        font-size: 18px;
        padding: 10px 10px 5px;
      }

      &--links {
        color: $disturbed-violet;
        font-size: 14px;
        overflow: hidden;
        padding: 5px 10px;
        text-align: left;
        text-decoration: none;
        text-overflow: ellipsis;
        white-space: nowrap;
        width: 90%;
      }

      &--active {
        background: $disturbed-violet;
        color: $white;
      }

      a:hover:not(&--active) {
        background: $bitter-milky;
      }

      a:focus {
        background: $disturbed-violet;
        color: white;
      }
    }
  }

  &__content {
    top: 50px;
  }
}

.nav {
  align-items: center;
  background: $historic-milky;
  border: 1px solid $forward-satin;
  border-width: 0 0 1px;
  color: $ready-cinnamon;
  display: flex;
  justify-content: space-between;
  left: 0;
  max-height: 50px;
  padding-left: 10px;
  position: fixed;
  right: 0;
  top: 0;
  transform: translate3d(0, 0, 0);
  z-index: 1030;
  -webkit-transform: translate3d(0, 0, 0);

  &__title {
    align-items: flex-start;
    display: flex;
    width: 100%;
  }

  &__user {
    align-items: center;
    color: $merciless-bronze;
    display: flex;
    font-size: 14px;
    justify-content: center;
    width: 15%;

    img {
      border-radius: 50%;
      height: 40px;
      margin-left: 10px;
      width: 40px;
    }
  }

  &__links {
    align-items: center;
    display: flex;
    justify-content: space-around;
    width: 15%;

    &--text-style {
      color: $ready-cinnamon;
      font-size: 14px;
      text-decoration: none;
    }
  }
}

.App-link {
  color: $fashionable-sapphire;
}

//media queries

@media only screen and (max-width: 575px) {
  .App-body .side-nav {
    position: relative;
  }
}
