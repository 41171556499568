.hotel-combine-editor {
  &__wrapper {
    display: inline-block;
    margin-left: auto;
  }

  &-content {
    &__hints {
      color: $merciless-bronze;
      font-style: italic;
      font-weight: bold;
      margin-bottom: 20px;
      text-align: center;
    }

     .hotel-attributes {
      margin: 10px;

      &-attribute {
        border-bottom: 2px solid $cool-gray-80;
        margin: 15px 0 15px 0;

        &__name {
          color: black;
          font-size: 16px;
          font-weight: bold;
          margin-bottom: 15px;
        }

        &__value {

          &-selector {
            margin: 5px 0 10px 0;
            .radio {
              display: flex;
              margin-bottom: 8px;
              &__label {
                color: black;
              }
            }
          }

          &-description {
            background: $smooth-satin;
            border: 1px solid $comparative-snow;
            border-radius: 4px;
            color: $bogus-licorice;
            font-size: 16px;
            margin-bottom: 20px;
            padding: 5px 10px;
            margin-top: 0;

            &-json {
              overflow-x: auto;
              color: $cool-gray-20;
            }
          }

          &-map {
            height: 250px;
            margin-bottom: 10px;
            width: 100%;
          }

          input {
            margin-bottom: 10px;
          }

          textarea {
            margin-bottom: 10px;
            width: 880px;
          }
        }
      }
    }

    &__submit {
      float: left;
      margin: 0 10px 10px;
      padding-bottom: 10px;
    }
    &__cancel {
      float: right;
      margin: 0 10px 10px;
      padding-bottom: 10px;
    }
  }
}
