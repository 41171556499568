.unmatched-human-verifiable-hotel {
  display: flex;
  justify-content: space-between;
  width: 100%;

  &__container {
    margin-bottom: 10px;
    width: 95%;
    margin-left: auto;
    margin-right: auto;

    &__hotel-card {
      border: solid $cool-gray-88 1px;
      height: auto;
      min-height: 515px;
      margin-bottom: 10px;
      padding: 10px;
    }
  }
}
