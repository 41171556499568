.file-upload {
  &__dropzone {
    align-items: center;
    background-color: $astronomical-silver;
    border-color: $cool-gray-88;
    border-radius: 2px;
    border-style: dashed;
    border-width: 2px;
    color: $cool-gray-80;
    display: flex;
    flex: 1;
    flex-direction: column;
    outline: none;
    padding: 20px;
    transition: border .24s ease-in-out;
    
    &--active {
      border-color: $downhill-violet;
    }
  }

  &__image-preview {
    height: 180px;
    object-fit: contain;
    object-position: center;
    width: 320px;
  }
}
