.fare-brand-form {
  margin: 10px;
  padding: 10px;

  &__error-message {
    border-top: 1px solid $silver-chalice;
    padding-top: 10px;
  }

  &__container {
    display: flex;
    padding-top: 10px;

    &-segment {
      margin-bottom: 40px;

      &-title {
        color: $bogus-licorice;
        font-size: 20px;
        font-weight: bold;
        margin-bottom: 20px;
        text-align: left;
      }

      &-field {
        font-size: 14px;
        text-align: left;

        &-radio-title {
          align-items: center;
          color: $merciless-bronze;
          display: flex;
          font-size: 1.6rem;
          margin-bottom: 1rem;
        }

        &-multi-select-box {
          text-align: left;

          .select-box__label {
            display: block;
            margin-bottom: 5px;
          }
        }

        .label-hint {
          cursor: pointer;
          display: inline-block;
          height: 1.6rem;
          margin-left: 0.6rem;
          position: relative;
          width: 1.6rem;
        }
      }
    }
  }

  &__title {
    color: $bogus-licorice;
    font-size: 32px;
    font-weight: bold;
    padding-bottom: 20px;
    text-align: left;
  }

  &__header {
    color: $bogus-licorice;
    font-size: 16px;
    font-weight: bold;
    margin: 0 auto 5px;
    text-align: left;
    width: 65%;
  }

  &__submit-button {
    align-items: center;
    display: flex;
    justify-content: flex-start;
    width: 40%;

    &-loading {
      align-items: center;
      display: flex;
    }
  }
}

.fare-brand-show {
  border-top: 1px solid $silver-chalice;
  display: flex;
  padding-top: 10px;

  &__submit-button {
    &-button {
      margin-top: 20px;
    }
  }

  &__supplier-brand-name {
    display: flex;
    padding-top: 13px;
  }

  &__segment {
    margin-bottom: 30px;

    &-title {
      color: $bogus-licorice;
      font-size: 20px;
      font-weight: bold;
      margin: 10px 0 20px;
      text-align: left;
    }

    &-infos {
      &--field {
        background: $shining-steel;
        border-radius: 4px;
        color: $white;
        font-size: 12px;
        font-weight: bold;
        padding: 5px;
        width: fit-content;
      }

      &--value {
        background: $smooth-satin;
        border: 1px solid $comparative-snow;
        border-radius: 4px;
        font-size: 16px;
        overflow-x: auto;
        padding: 5px 10px;
      }
    }
  }
}
