@import "@wtag/rcl-button/dist/styles.min";
@import "../variables";

.button {
  background: $evangelical-ivory;
  border: 0.1rem solid $medium-gray;
  border-radius: 3rem;
  color: $merciless-bronze;
  font-size: 1.6rem;
  font-weight: normal;
  line-height: 1.5em;
  margin: 0 0.3rem;
  padding: 0.3rem 1.6rem;
}
