// !!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!
// !! COPIED FROM RCL as it's not available in Meta, yet !!
// !! Please don't modify                                !!
// !!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!

$bolder-font-weight: 400;
$cool-gray-82: #cbcbcb;

.checkbox {
  align-items: center;
  color: $cool-gray-30;
  display: inline-flex;
  font-size: 14px;
  font-weight: $bolder-font-weight;
  margin: 0 16px 0 0;

  &:last-of-type,
  &:only-of-type {
    margin: 0;
  }

  &__input {
    display: none;

    &:checked + .checkbox__check {
      background: $cool-gray-40;

      &::before,
      &::after {
        opacity: 1;
      }

      &::before {
        transform: rotate(50deg);
      }

      &::after {
        transform: rotate(-50deg);
      }
    }
  }

  &__check {
    border: 2px solid $cool-gray-40;
    border-radius: 3px;
    box-sizing: border-box;
    display: inline-block;
    flex-basis: 20px;
    height: 20px;
    overflow: hidden;
    position: relative;
    transition: 0.2s;
    width: 20px;

    &::before,
    &::after {
      background: $white;
      content: '';
      height: 2px;
      opacity: 0;
      position: absolute;
      transition: 0.2s;
    }

    &::before {
      left: 1px;
      top: 50%;
      width: 6px;
    }

    &::after {
      left: 3px;
      top: 45%;
      width: 12px;
    }
  }

  &__label {
    // "1 0 auto" instead of just "1" to fix IE11 issues
    flex: 1 0 auto;
    margin: 0 0 0 8px;
  }

  &--small {
    font-size: 14px;

    .checkbox__label {
      margin-left: 8px;
    }

    .checkbox__check {
      flex-basis: 16px;
      height: 16px;
      width: 16px;

      &::before {
        width: 5px;
      }

      &::after {
        left: 3px;
        top: 45%;
        width: 9px;
      }
    }

    .label-hint {
      font-size: 14px;
      margin-left: 7px;
    }
  }

  &--large {
    font-size: 16px;

    .checkbox__label {
      margin-left: 12px;
    }

    .checkbox__check {
      flex-basis: 22px;
      height: 22px;
      width: 22px;

      &::before {
        left: 0;
        top: 56%;
        width: 7px;
      }

      &::after {
        width: 16px;
      }
    }

    .label-hint {
      margin-left: 9px;
    }
  }

  &--huge {
    font-size: 20px;

    .checkbox__label {
      margin-left: 14px;
    }

    .checkbox__check {
      flex-basis: 24px;
      height: 24px;
      width: 24px;

      &::before {
        left: 0;
        top: 60%;
        width: 8px;
      }

      &::after {
        width: 18px;
      }
    }

    .label-hint {
      margin-left: 9px;
    }
  }

  &-dark {
    color: $cool-gray-80;

    .checkbox__check {
      border-color: $cool-gray-80;
    }

    .checkbox__input:checked + .checkbox__check {
      background: $cool-gray-80;

      &::before,
      &::after {
        background: $cool-gray-30;
      }
    }

    &.checkbox {
      &--disabled {
        .checkbox__check {
          background: $cool-gray-80;

          &::before {
            background: $cool-gray-30;
          }
        }
      }
    }
  }

  &--disabled {
    opacity: 0.2;
    pointer-events: none;

    .checkbox__input:not(:checked) + .checkbox__check {
      background: $cool-gray-40;

      &::before {
        left: 50%;
        opacity: 1;
        top: 50%;
        transform: translate(-50%, -50%);
        width: 70%;
      }
    }
  }

  .label-hint {
    svg {
      fill: $cool-gray-82;
    }
  }
}
