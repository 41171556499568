.empty-content-placeholder {
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-top: 10%;

  &__icon {
    align-items: center;
    background-color: $historic-milky;
    border-radius: 50%;
    display: flex;
    height: 80px;
    justify-content: center;
    width: 80px;
  }

  &__text {
    color: $leaky-rust;
    font-size: 14px;
    font-weight: bolder;
    padding-top: 10px;
  }
}
