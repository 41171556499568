.coming-soon {
  align-items: center;
  display: flex;
  justify-content: center;
  margin-top: 15%;

  &__content {
    &-icon {
      background-color: $historic-milky;
      border-radius: 50%;
      height: 80px;
      width: 80px;
    }

    &-text {
      color: $leaky-rust;
      font-size: larger;
      font-weight: bolder;
      padding-top: 10px;
    }
  }
}
