.error-component {
  background: $error-background;
  color: $error-text;
  padding: 10px;
  border: 1px solid black;
  border-radius: 4px;
  text-align: center;
  font-size: 14px;
  font-weight: 400;
  white-space: normal;
  max-width: 80%;
  box-sizing: border-box;
}
