.human-verified-hotel {
  display: flex;
  justify-content: space-between;
  width: 100%;

  &__container {
    margin-bottom: 10px;
    width: 48%;

    &__hotel-card {
      border: solid $cool-gray-88 1px;
      height: auto;
      min-height: 515px;
      margin-bottom: 10px;
      padding: 10px;
    }
  }
}

.hotel-information {

  &__map{
    height: 300px;
    width: 100%;
  }

  &__header {
    display: flex;
    justify-content: space-between;
  }

  &__field {
    margin: 8px 0 8px 0;
    &-attribute{
      color: $merciless-bronze;
      font-weight: bold;
      margin-right: 4px;
      display: inline;
    }
  }
}

.hotel-details {

  &--collapse {
    color: $tomato;
    cursor: pointer;
  }
}
