.hashtag {
  &-wrapper {
    margin-left: auto;
  }

  &-content {
    &__add-new {
      margin-top: 20px;
    }

    &__submit {
      margin-top: 10px;
    }

    &__list {
      margin-top: 20px;
      font-weight: normal;
    }
  }
}
