@import '../../stylesheet/variables';

.unsplash-images {
  .base-modal__header-close {
    display: flex;
    justify-content: flex-end;
  }

  &__delete-button {
    display: flex;
    padding-left: 20px;
    padding-top: 20px;
  }

  &__loading {
    display: flex;
    justify-content: center;

    &-title {
      font-size: 12px;
    }
  }

  &__list {
    display: flex;
    flex-flow: row wrap;
    height: 550px;
    margin-top: 10px;
    overflow: auto;
    padding: 0 20px;

    &-item {
      box-sizing: border-box;
      cursor: pointer;
      display: flex;
      line-height: 0;
      padding: 8px;
      width: 25%;

      img {
        border-radius: 3px;
        display: block;
        width: 100%;
      }

      &-overlay {
        opacity: 0;
      }

      &--selected {
        position: relative;

        .unsplash-images__list-item-overlay {
          align-items: center;
          background-color: rgba($tertiary-accent, 0.6);
          border-radius: 3px;
          bottom: 8px;
          display: flex;
          font-size: 40px;
          justify-content: center;
          left: 8px;
          opacity: 1;
          position: absolute;
          right: 8px;
          top: 8px;

          .icon {
            svg {
              fill: $white;
            }
          }
        }
      }
    }
  }

  &__list-small {
    &__list {
      height: 160px;
    }
  }

  &__list-medium {
    &__list {
      height: 340px;
    }
  }

  &__select-button {
    padding-top: 10px;
  }

  &--without-images {
    align-items: center;
    height: 200px;
    justify-content: center;
  }
}
