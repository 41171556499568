.create-new-hotel {
  &__wrapper {
    margin-left: auto;
    display: inline-block;
  }

  &-content {
    &__attributes {
      margin: 10px;

      &-map{
        height:250px;
        width:100%;
        margin-bottom: 10px;
      }
      input {
        margin-bottom: 10px;
      }
      textarea {
        margin-bottom: 10px;
        width: 880px;
      }
    }

    &__submit {
      float: left;
      margin: 0 10px 10px;
      padding-bottom: 10px;
    }
    &__cancel {
      float: right;
      margin: 0 10px 10px;
      padding-bottom: 10px;
    }

    &__list {
      margin-top: 20px;
      font-weight: normal;
    }
  }
}
