$downhill-violet: #2877e4;
$evangelical-ivory: #eceef3;
$merciless-bronze: #44474d;
$bogus-licorice: #333333;
$comparative-snow: #e3e3e3;
$astronomical-silver: #f9f9f9;
$shining-steel: #5bc0de;
$smooth-satin: #f5f5f5;
$disturbed-violet: #428bca;
$historic-milky: #f8f8f8;
$forward-satin: #e7e7e7;
$awake-amber: #555555;
$tomato: #ff6347;
$white: rgb(254, 254, 252);
$bitter-milky: #eeeeee;
$ready-cinnamon: #777777;
$fashionable-sapphire: #09d3ac;
$error-background: rgba(249, 185, 60, 0.6);
$error-text: rgba(0, 0, 0, 0.7);
$leaky-rust: #888c9d;
$black: #000000;
$silver-chalice: #a9a9a9;

$cool-gray-20: #303030;
$white: rgb(255, 255, 255);
$cool-gray-100: #f8f9fd;
$cool-gray-98: #f9f9f9;
$cool-gray-90: #e2e2e2;
$cool-gray-88: #dddddd;
$cool-gray-80: #c6c6c6;
$cool-gray-35: #525252;
$cool-gray-30: #c6c6c6;
$cool-gray-20: #303030;

$tertiary-accent: #4b73a7;
$medium-gray: #dadde4;
