.list {
  display: flex;
  flex-direction: column;
  padding: 10px;
  margin: 10px;
  text-align: left;

  &__pagination {
    display: flex;
    justify-content: flex-end;
  }

  &__header {
    display: flex;
    font-size: 16px;
    font-weight: bold;
    justify-content: space-between;
    padding-left: 8px;
    padding-right: 10px;

    &--no-padding {
      padding: 0;
    }
  }

  &__search {
    &-post-icon {
      right: 12px;
      top: -13px;
      width: 20px;
    }
  }

  &__title {
    color: $bogus-licorice;
    font-size: 36px;
    font-weight: bold;
    padding-bottom: 20px;
    text-align: left;
  }

  &__data {
    border-top: 1px solid $comparative-snow;
    display: flex;
    font-size: 14px;
    justify-content: space-between;
    min-height: 42px;
    padding-left: 10px;
    padding-right: 10px;
  }

  &__data:nth-child(odd) {
    background: $astronomical-silver;
  }

  &__value {
    align-items: center;
    display: flex;
    justify-content: flex-start;
    max-height: 42px;
    width: 300px;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;

    &-clickable {
      cursor: pointer;

      &-icon {
        font-size: 12px;
        margin-left: 4px;

        &:last-of-type {
          margin-left: 0;
        }

        svg {
          fill: $leaky-rust;
        }

        &--active {
          svg {
            fill: $black;
          }
        }
      }
    }

    &-text {
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      width: 100%;
    }

    &-title {
      text-transform: capitalize;
    }

    &--actions {
      justify-content: center;
      width: 230px;

      &-small {
        width: 150px;
      }
    }
  }

  &__table {
    border: 1px solid $comparative-snow;
    border-top: none;
  }

  &__tabs {
    .r-tabs {
      &__tab-list {
        list-style-position: inside;
        list-style-type: disc;
        padding-inline-start: 0;
      }
    }
  }
}
