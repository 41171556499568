// !!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!
// !! COPIED FROM RCL as it's not available in Meta, yet !!
// !! Please don't modify                                !!
// !!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!

$black: #000000;
$cool-gray-95: #f1f1f1;
$light-shade: rgba(0, 0, 0, 0.1);

@mixin reset-all-defaults {
  box-sizing: border-box;
  list-style: none;
  margin: 0;
  padding: 0;
}

.base-modal {
  align-items: center;
  bottom: 0;
  color: $cool-gray-30;
  display: flex;
  justify-content: center;
  left: 0;
  opacity: 0;
  position: fixed;
  right: 0;
  text-align: left;
  top: 0;
  transition: 0.2s 0.2s;
  visibility: hidden;
  z-index: 30;

  &--small {
    .base-modal__container {
      max-width: 30.4rem;
    }
  }

  &--large {
    .base-modal__container {
      max-width: 70.4rem;
    }
  }

  &--huge {
    .base-modal__container {
      max-width: 96.8rem;
    }
  }

  &--square,
  &--full {
    .base-modal__container {
      height: 100%;
      max-height: 100%;
    }

    .base-modal__content {
      border-radius: 0;
      max-height: 100vh;
    }

    .base-modal__close {
      right: 1rem;
      top: 1rem;
    }
  }

  &--full {
    .base-modal__close {
      right: -38px;
      top: 5px;
    }
  }

  &__hero-icon {
    @include reset-all-defaults;
    background: $cool-gray-95;
    border-radius: 50%;
    display: inline-block;
    height: 65px;
    margin: 15px 0 30px;
    padding: 15px;
    width: 65px;

    svg {
      fill: $cool-gray-30;
      height: 100%;
      width: 100%;
    }
  }

  &__button {
    bottom: 0;
    left: 50%;
    position: absolute;
    transform: translateX(-50%);
    width: 100%;
  }

  &__text {
    svg,
    &--small-svg svg {
      display: inline-block;
      height: 30px;
      position: relative;
      top: 4px;
      width: 30px;
    }
  }

  &--default-padding {
    padding: 1rem;
  }

  &__wrapper {
    display: inline-block;

    // TODO REMOVE THIS line after input tag specific css is removed from agent
    input[type='checkbox'].base-modal__state {
      display: none;
    }
  }

  &__container {
    display: flex;
    max-height: 80%;
    max-width: 50.4rem;
    opacity: 0;
    position: relative;
    transition: 0.2s 0.1s;
    width: 80%;
    z-index: 100;
  }

  &__content {
    background: $white;
    border-radius: 0.8rem;
    line-height: normal;
    max-height: 80vh;
    overflow: auto;
    width: 100%;

    .button-field {
      align-items: center;
      background: $cool-gray-98;
      border-top: 1px solid $light-shade;
      display: flex;
      justify-content: center;
      padding: 16px;
    }
  }

  &__header {
    border-bottom: 1px solid $cool-gray-90;
    font-size: 2.2rem;
    font-weight: 500;
    padding: 10px 16px;
  }

  &__body {
    flex-basis: 100%;

    .actions {
      margin: 20px 0 0;
    }
  }

  &__action {
    margin: 20px 0 0;

    .button {
      display: inline-block;
    }
  }

  &__close {
    @include reset-all-defaults;
    background: $cool-gray-35;
    border-radius: 50%;
    cursor: pointer;
    display: inline-flex;
    height: 3rem;
    padding: 0.7rem;
    position: absolute;
    right: -3rem;
    top: -3rem;
    width: 3rem;

    svg {
      fill: $cool-gray-90;
      width: 100%;
    }
  }

  &__link {
    cursor: pointer;
    display: inline-block;

    &--disabled {
      opacity: 0.4;
      pointer-events: none;
    }
  }

  &__background {
    background: rgba($cool-gray-30, 0.75);
    bottom: 0;
    left: 0;
    position: fixed;
    right: 0;
    top: 0;
    z-index: 99;

    &:hover ~ .base-modal__container .base-modal__close {
      background: $black;
    }
  }

  &__state {
    display: none;

    &:checked {
      ~ .base-modal {
        opacity: 1;
        transition: 0.2s;
        visibility: visible;

        .base-modal__container {
          opacity: 1;
        }
      }
    }
  }

  &--split {
    text-align: center;

    .base-modal__body {
      display: flex;
    }
  }

  &--dark {
    .base-modal__background {
      background: rgba($cool-gray-20, 0.75);
    }

    .base-modal__content {
      background: $cool-gray-30;
      color: $cool-gray-80;
    }

    .base-modal__close {
      background: $cool-gray-30;
    }

    .base-modal__hero-icon {
      background: $cool-gray-20;

      svg {
        fill: $cool-gray-95;
      }
    }
  }
}

// To place all the modals above google map's +/- and share location buttons and mapquest logo

.leaflet-top,
.leaflet-bottom,
.location__map .location__share {
  z-index: 1;
}
