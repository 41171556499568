.name-translation-form {

  &__supplier-brand-name {
    display: flex;
    padding-top: 13px;
  }

  &__container {
    display: flex;
    padding-top: 10px;

    &-segment {
      margin-bottom: 40px;

      &-field {
        font-size: 14px;
        text-align: left;
      }
    }
  }

  &__submit-button {
    align-items: center;
    display: flex;
    justify-content: flex-start;
    margin-top: 20px;
    width: 40%;
  }
}
