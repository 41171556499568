@import "@wtag/rcl-input/dist/styles.min";
@import "../variables";

.input {
  &__field {
    background: $cool-gray-100;
    border: 0.1rem solid $medium-gray;
    border-radius: 0.3rem;
    box-sizing: border-box;
    color: $merciless-bronze;
    font-size: 1.6rem;
    height: 3.2rem;
    padding: 0.3rem 1.6rem;
    width: 100%;
  }

  &__field-holder {

    &--small {
      background: $cool-gray-100;
      height: 3.2rem;
      padding: 0;
    }
  }

  &__label {
    align-items: center;
    color: $merciless-bronze;
    display: flex;
    font-size: 1.6rem;
    margin: 0 0 0.3rem;

    &--small {
      align-items: center;
    }
  }
}

.icon-button {
  &__icon {
    display: block;
    font-size: 2em;
    justify-content: right;
  }
}

.keyword {
  &-wrapper {
    margin-left: auto;
  }

  &-content {
    &__add-new {
      margin-top: 20px;
    }

    &__submit {
      margin-top: 10px;
    }

    &__list {
      font-weight: normal;
      margin-top: 20px;
    }
  }
}
