.header {
  align-items: center;
  display: flex;
  font-size: 14px;
  font-weight: bold;
  justify-content: space-between;

  &__title {
    color: $bogus-licorice;
    font-size: 36px;
    font-weight: bold;
    padding-bottom: 20px;
    text-align: left;
  }

  &__actions {
    display: inline-flex;
  }

  &__children {
    display: flex;
    margin-left: auto;
  }
}
