// !!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!
// !! COPIED FROM RCL as it's not available in Meta, yet !!
// !! Please don't modify                                !!
// !!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!

$cool-gray-100: #f8f9fd;
$cool-gray-99: #fcfcfc;
$cool-gray-98: #f9f9f9;
$cool-gray-94: #eeeeee;
$cool-gray-90: #e2e2e2;
$cool-gray-89: #fefefc;
$cool-gray-88: #dddddd;
$cool-gray-80: #c6c6c6;
$cool-gray-40: #5e5e5e;
$cool-gray-35: #525252;
$cool-gray-30: #474747;
$cool-gray-25: #3b3b3b;
$cool-gray-18: #2c2c2c;
$cool-gray-15: #262626;
$cool-gray-10: #1b1b1b;

.r-tabs {
  &--small {
    .r-tabs__tab {
      font-size: 1.2rem;
    }
  }

  &--large {
    .r-tabs__tab {
      padding: 0.8rem 2.5rem;
    }
  }

  &__tab-list {
    border-bottom: 0.1rem solid $cool-gray-90;
    display: flex;
    flex-flow: row wrap;
    font-size: 1.6rem;
  }

  &__tab {
    background: $cool-gray-98;
    border: 0.1rem solid $cool-gray-90;
    border-left: none;
    color: $cool-gray-40;
    cursor: pointer;
    font-weight: 300;
    margin: 0 0 -0.1rem;
    padding: 0.6rem 2.5rem;

    &:hover {
      background: $cool-gray-88;
      color: $cool-gray-30;
    }

    &:active {
      background: $cool-gray-30;
      color: $white;
    }

    &--selected {
      background: $white;
      border-bottom: 0.1rem solid $white;
      color: $cool-gray-20;
      font-weight: normal;
      outline: none;
      pointer-events: none;
    }

    &--disabled {
      background: $cool-gray-94;
      color: $cool-gray-80;
      pointer-events: none;
    }

    &-panel {
      display: none;
      font-size: 1.6rem;
      padding: 2rem 2.5rem;

      &--selected {
        display: block;
      }

      .r-tabs {
        &__tab-list,
        &__tab-panel {
          margin: 0 -2.5rem;
        }
      }
    }
  }

  &-dark {
    .r-tabs {
      &__tab-list {
        border-bottom: 0.1rem solid $cool-gray-20;
      }

      &__tab {
        background: $cool-gray-25;
        border: 0.1rem solid $cool-gray-20;
        border-left: none;
        color: $cool-gray-80;

        &:hover {
          background: $cool-gray-18;
        }

        &:active {
          background: $cool-gray-90;
          color: $cool-gray-30;
        }

        &--selected {
          background: $cool-gray-30;
          border-bottom: 0.1rem solid $cool-gray-30;
          color: $cool-gray-90;
        }

        &--disabled {
          background: $cool-gray-35;
          color: rgba($cool-gray-80, 0.2);
        }

        &-panel {
          background: $cool-gray-30;
          color: $cool-gray-90;
        }
      }
    }
  }
}
