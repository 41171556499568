.platform-feature-list {
  display: flex;
  flex-direction: column;
  margin: 10px;
  padding: 10px;
  text-align: left;

  &__pagination {
    display: flex;
    justify-content: flex-end;
  }

  &__header {
    display: flex;
    font-size: 14px;
    font-weight: bold;
    justify-content: space-between;
    padding-left: 8px;
    padding-right: 10px;

    &--no-padding {
      padding: 0;
    }
  }

  &__title {
    color: $bogus-licorice;
    font-size: 36px;
    font-weight: bold;
    padding-bottom: 20px;
    text-align: left;
  }

  &__data {
    border-top: 1px solid $comparative-snow;
    background: $astronomical-silver;
    display: flex;
    font-size: 14px;
    justify-content: space-between;
  }

  &__value {
    align-items: center;
    display: flex;
    justify-content: center;
    max-height: 42px;
    overflow: hidden;
    text-overflow: ellipsis;
    width: 300px;
    white-space: nowrap;

    &-title {
      text-transform: capitalize;
    }

    &-topic {
      display: flex;
      margin-right: 15px;
      width: 750px;
    }

    &-order {
      font-weight: bold;
      margin: 0 5px;
    }

    &-icon {
      margin: auto 0 auto auto;

      .a {
        fill: black;
      }
    }

    &--actions {
      justify-content: center;
      width: 250px;
    }
  }

  &__table {
    border: 1px solid $comparative-snow;
    border-top: none;
  }
}
