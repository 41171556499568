.flight {
  &-form {
    &__airline {
      .react-selectize.default.root-node {
        .react-selectize-control
        .react-selectize-search-field-and-selected-values {
          height: 80%;
          max-width: 90%;
          padding-top: 5px;
        }
      }

      .selectbox {
        &__field
        .react-selectize.root-node {
          .react-selectize-control
          .value-wrapper {
            max-width: 100%;
          }
        }
      }

      .simple-select.react-selectize.root-node
      .simple-value {
        max-width: 100%;

        span {
          display: block;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
        }
      }
    }
  }
}
