.travel-news {
  &-form {
    &__country {
      .simple-select.react-selectize.root-node {
        .simple-value span {
          overflow: hidden;
          padding-top: 5px;
          text-overflow: ellipsis;
          white-space: nowrap;
          width: 230px;
        }

        .react-selectize-search-field-and-selected-values {
          flex-wrap: nowrap;
        }
      }
    }

    &__hashtags {
      .select-box {
        &__width {
          &--small {
            .select-box {
              &__control {
                width: 100%;
              }
            }
          }
        }
      }
    }
  }
}
