.language-selection {
  .selectbox__field .react-selectize.root-node {
    width: 180px;
  }

  .selectbox__field .react-selectize.root-node .react-selectize-control .resizable-input {
    caret-color: transparent;
    font-size: 1.2rem;
  }
}
