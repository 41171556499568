.combine-hotel-view {
  &__wrapper {
    display: inline-block;
    margin-left: auto;
  }

  &-content {
    color: $merciless-bronze;

    &__submit {
      margin: 10px;
      float: left;
    }
    &__cancel {
      margin: 10px;
      float: right;
    }
  }
}
