.city-list {
  display: flex;
  flex-direction: column;
  margin: 10px;
  padding: 10px;

  &__data {
    border-top: 1px solid $comparative-snow;
    display: flex;
    font-size: 14px;
    height: 42px;
    justify-content: space-between;
    padding-left: 10px;
    padding-right: 10px;

    &-value {
      align-items: center;
      display: flex;
      justify-content: flex-start;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      width: 33.3333%;

      &-clickable {
        cursor: pointer;

        &-icon {
          font-size: 10px;
          margin-left: 20px;

          svg {
            fill: $black;
          }
        }
      }

      &--actions {
        justify-content: flex-end;
        width: 230px;
      }
    }

    &--nested {
      justify-content: flex-start;
      padding-left: 30px;
    }
  }

  &__data:nth-child(odd) {
    background: $astronomical-silver;
  }
}
