.faq-form {
  &__hashtags {
    .select-box {
      &__width {
        &--large {
          .select-box {
            &__control {
              width: 100%;
            }
          }
        }
      }
    }
  }
}
