.render-image {
  &__preview {
    display: block;
    padding-bottom: 10px;
    width: 480px;

    img {
      display: block;
      height: auto;
      max-width: 100%;
    }
  }

  &__label {
    background: $smooth-satin;
    border: 1px solid $comparative-snow;
    border-radius: 4px;
    font-size: 16px;
    margin-bottom: 20px;
    padding: 5px 10px;
  }
}
