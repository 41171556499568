@import "@wtag/react-comp-lib/dist/react-comp-lib.min";
@import "../variables";

.text-field {
  &--small,
  &--tiny {
    background: $cool-gray-100;
    color: $merciless-bronze;
    font-size: 16px;

    &::placeholder {
      color: $merciless-bronze;
    }
  }

  &__label {
    &--small,
    &--tiny {
      align-items: center;
      color: $merciless-bronze;
      display: flex;
      font-size: 1.6rem;
      margin: 0 0 0.3rem;
    }
  }
}
