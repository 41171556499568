// !!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!
// !! COPIED FROM RCL as it's not available in Meta, yet !!
// !! Please don't modify                                !!
// !!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!

/* eslint-disable jsx-a11y/label-has-associated-control */

$cool-gray-40: #5e5e5e;
$cool-gray-80: #c6c6c6;

.radio {
  align-items: center;
  color: $cool-gray-30;
  display: inline-flex;
  font-size: 1.6rem;
  margin: 0 1.6rem 0 0;

  &:last-of-type,
  &:only-of-type {
    margin: 0;
  }

  &__input {
    display: none;

    &:checked + .radio__check {
      &::before {
        background: $cool-gray-40;
        height: 60%;
        width: 60%;
      }
    }
  }

  &__check {
    border: 0.2rem solid $cool-gray-40;
    border-radius: 50%;
    display: inline-block;
    height: 1.8rem;
    overflow: hidden;
    position: relative;
    transition: 0.2s;
    width: 1.8rem;

    &::before {
      border-radius: 50%;
      content: '';
      height: 100%;
      left: 50%;
      position: absolute;
      top: 50%;
      transform: translate(-50%, -50%);
      transition: 0.2s;
      width: 100%;
    }
  }

  &__label {
    margin: 0 0 0 0.8rem;
  }

  &--small {
    font-size: 1.2rem;

    .radio__check {
      height: 1.6rem;
      width: 1.6rem;
    }
  }

  &--large {
    font-size: 1.8rem;

    .radio__check {
      height: 2.2rem;
      width: 2.2rem;
    }
  }

  &--huge {
    font-size: 2.2rem;

    .radio__check {
      height: 2.4rem;
      width: 2.4rem;
    }
  }

  &--large,
  &--huge {
    .radio__label {
      margin: 0 0 0 1.2rem;
    }
  }

  &-dark {
    color: $cool-gray-80;

    .radio__check {
      border-color: $cool-gray-80;
    }

    .radio__input:checked + .radio__check {
      &::before {
        background: $cool-gray-80;
      }
    }
  }

  &--disabled {
    opacity: 0.2;
    pointer-events: none;
  }
}
